import { BButton, BCard, BCol, BFormGroup, BRow } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import ImageUploader from '@/components/controls/ImageUploader'
import Switcher from '@/components/controls/Switcher'
import { copyTextToClipboard } from '@/helpers/copy'
import { imageFormFile } from '@/helpers/image'
import { showMessage } from '@/helpers/notifications'
import { i18nStore, promotionStore } from '@/store'
import { emptyLocalizedField, LocalizedField } from '@/store/i18nStore'
import { Component, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

import './style.scoped.scss'

@Component
export default class BarTab extends tsx.Component<{}> {
  isPreviewVisible = false
  imagePreview = ''

  get image() {
    return promotionStore.barImage.value
  }

  get isLandingActive() {
    return promotionStore.activeTabs.includes('landing')
  }

  get uselandingUrl() {
    return promotionStore.barUseLandingUrl && this.isLandingActive
  }

  get usePromoTitle() {
    return promotionStore.barUsePromoTitle
  }

  get promoTitle() {
    return promotionStore.title.value
  }

  get landingUrl() {
    return promotionStore.landingUrl.value
  }

  @Watch('promoTitle')
  onPromoTitleChanged(value: LocalizedField) {
    if (promotionStore.barUsePromoTitle) {
      promotionStore.barTitle.set(value)
    }
  }

  @Watch('usePromoTitle')
  onUseTitleChanged() {
    if (this.usePromoTitle) {
      promotionStore.barTitle.set(promotionStore.title.value)
    } else {
      promotionStore.barTitle.set(emptyLocalizedField())
    }
    promotionStore.barTitle.reset()
  }

  @Watch('landingUrl')
  onLandingUrlChanged(value: string) {
    if (this.uselandingUrl) {
      promotionStore.barUrl.set(`promo/${value}`)
    }
  }

  @Watch('uselandingUrl')
  onUseUrlChanged() {
    if (this.uselandingUrl) {
      promotionStore.barUrl.set(`promo/${promotionStore.landingUrl.value}`)
    } else {
      promotionStore.barUrl.set('')
    }
    promotionStore.barUrl.reset()
  }

  @Watch('image')
  async onImageChanged() {
    if (this.image instanceof File) {
      this.imagePreview = await imageFormFile(this.image)
    } else if (typeof this.image === 'string') {
      this.imagePreview = this.image
    } else {
      this.imagePreview = ''
    }
  }

  async mounted() {
    if (typeof promotionStore.barImage.value === 'string') {
      this.imagePreview = promotionStore.barImage.value
    }
  }

  copyUrl() {
    if (
      copyTextToClipboard(
        `${process.env.VUE_APP_MSC_URL}/${promotionStore.barUrl.value}`,
      )
    ) {
      showMessage('Ссылка скопирована')
    }
  }

  protected render() {
    return (
      <BCard>
        <BRow>
          <BCol sm={6}>
            <FormInput
              label="Текст"
              fieldValidator={promotionStore.barTitle}
              disabled={promotionStore.barUsePromoTitle}
              scopedSlots={{
                top: () => (
                  <Switcher
                    value={promotionStore.barUsePromoTitle}
                    onInput={value => (promotionStore.barUsePromoTitle = value)}
                  >
                    Использовать название акции
                  </Switcher>
                ),
              }}
            />
            <FormInput
              label="Текст кнопки"
              fieldValidator={promotionStore.barButtonText}
            />
            <FormInput
              label="Ссылка"
              fieldValidator={promotionStore.barUrl}
              disabled={this.uselandingUrl}
              prepend={`${process.env.VUE_APP_MSC_URL}/`}
              scopedSlots={{
                top: () =>
                  this.isLandingActive ? (
                    <Switcher
                      value={promotionStore.barUseLandingUrl}
                      onInput={value =>
                        (promotionStore.barUseLandingUrl = value)
                      }
                    >
                      Использовать url лендинга
                    </Switcher>
                  ) : null,
                append: () => (
                  <BButton
                    onClick={this.copyUrl}
                    variant="outline-primary"
                    class="px-50"
                  >
                    <feather-icon icon="CopyIcon" />
                  </BButton>
                ),
              }}
            />
            <FormInput
              label="Цикл показа (часы)"
              fieldValidator={promotionStore.barTimer}
              type="number"
            />
          </BCol>
          <BCol sm={6}>
            <FormInput
              label="Цвет текста"
              fieldValidator={promotionStore.barColor}
            />
            <FormInput
              label="Background полосы (css)"
              fieldValidator={promotionStore.barBackground}
              textarea
              rows={3}
            />
            <BFormGroup
              label="Изображение"
              invalid-feedback={promotionStore.barImage.errorText}
              state={promotionStore.barImage.hasError ? false : null}
            >
              <ImageUploader
                value={promotionStore.barImage.value}
                onInput={promotionStore.barImage.set}
                state={promotionStore.barImage.hasError ? false : null}
                width="150px"
              />
            </BFormGroup>
            <BButton
              variant="outline-primary"
              onClick={() => (this.isPreviewVisible = !this.isPreviewVisible)}
            >
              Предпросмотр
            </BButton>
          </BCol>
        </BRow>

        {this.isPreviewVisible && (
          <div
            class="bar-preview d-flex align-items-center justify-content-center"
            style={{
              color: promotionStore.barColor.value,
              background: promotionStore.barBackground.value,
            }}
            onClick={() => (this.isPreviewVisible = false)}
          >
            <div class="image">
              {!!this.imagePreview && <img src={this.imagePreview} alt="" />}
            </div>
            <span class="discount">
              {promotionStore.barTitle.value[i18nStore.selectedLanguage]}
            </span>
            <span>До конца акции</span>
            <span class="timer">3 : 23 : 59 : 02</span>
            <div
              class="button"
              style={{ background: promotionStore.barColor.value }}
            >
              {promotionStore.barButtonText.value[i18nStore.selectedLanguage]}
            </div>
            <feather-icon class="close-btn" icon="XIcon" size="20" />
          </div>
        )}
      </BCard>
    )
  }
}
