import { BButton, BTab, BTabs } from '@/components/bootstrap'
import LoaderOverlay, { Task } from '@/components/LoaderOverlay'
import PopupTab from '@/components/promotions/tabs/Popup'
import BarTab from '@/components/promotions/tabs/Bar'
import BlockTab from '@/components/promotions/tabs/Block'
import ConditionsTab from '@/components/promotions/tabs/Conditions'
import LandingTab from '@/components/promotions/tabs/Landing'
import { promotionStore } from '@/store'
import { Component, Ref, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { showError } from '@/helpers/notifications'
import { uploadImage } from '@/helpers/upload'
import axios from '@/libs/axios'

@Component
export default class PromotionEditPage extends tsx.Component<{}> {
  @Ref() readonly overlay!: LoaderOverlay

  get activeTabs() {
    return promotionStore.activeTabs
  }

  @Watch('activeTabs')
  onHomeworkTypeChanged() {
    if (!this.activeTabs.includes('courseBlock')) {
      promotionStore.courseBlockValidator.resetErrors()
    }
    if (!this.activeTabs.includes('landing')) {
      promotionStore.landingValidator.resetErrors()
    }
    if (!this.activeTabs.includes('popup')) {
      promotionStore.popupValidator.resetErrors()
    }
    if (!this.activeTabs.includes('promoBar')) {
      promotionStore.promoBarValidator.resetErrors()
    }
  }

  async savePromotion(close = true) {
    let result = await promotionStore.validate()
    if (!result) {
      showError('При заполнении полей допущены ошибки')
      return
    }
    result = await this.overlay.show(this.getLoadingTasks())
    if (!result) {
      return
    }
    if (close) {
      this.$router.push('/prices')
    } else {
      history.replaceState(
        null,
        '',
        location.href.replace(
          'promotions/add',
          `promotions/edit/${promotionStore.currentPromotionId}`,
        ),
      )
    }
  }

  getLoadingTasks(): Task[] {
    const tasks: Task[] = []

    if (this.activeTabs.includes('courseBlock')) {
      if (promotionStore.blockImage.value instanceof File) {
        tasks.push({
          title: `Загрузка изображения ${promotionStore.blockImage.value.name}`,
          action: async () => {
            const url = await uploadImage(
              promotionStore.blockImage.value as File,
              'promotion/course',
            )
            if (url) {
              promotionStore.blockImage.set(url)
              return true
            }
            return false
          },
        })
      }
    }
    if (this.activeTabs.includes('landing')) {
      if (promotionStore.landingImage.value instanceof File) {
        tasks.push({
          title: `Загрузка изображения ${promotionStore.landingImage.value.name}`,
          action: async () => {
            const url = await uploadImage(
              promotionStore.landingImage.value as File,
              'promotion/landing',
            )
            if (url) {
              promotionStore.landingImage.set(url)
              return true
            }
            return false
          },
        })
      }
    }
    if (this.activeTabs.includes('popup')) {
      if (promotionStore.popupMediaRu.value instanceof File) {
        if (promotionStore.popupMediaRu.value.name.endsWith('.mp4')) {
          tasks.push({
            title: `Загрузка видео ${promotionStore.popupMediaRu.value.name}`,
            action: async () => {
              try {
                const formData = new FormData()
                formData.append(
                  'file',
                  promotionStore.popupMediaRu.value as File,
                )

                const response = await axios.post<{ url: string }>(
                  'v1/uploads/promotion-video',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  },
                )
                promotionStore.popupMediaRu.set(response.data.url)
                return true
              } catch (error) {
                console.error(error)
                showError('При загрузке видео произошла ошибка')
                return false
              }
            },
          })
        } else {
          tasks.push({
            title: `Загрузка изображения ${promotionStore.popupMediaRu.value.name}`,
            action: async () => {
              const url = await uploadImage(
                promotionStore.popupMediaRu.value as File,
                'promotion/popup',
              )
              if (url) {
                promotionStore.popupMediaRu.set(url)
                return true
              }
              return false
            },
          })
        }
      }

      if (promotionStore.popupMediaUa.value instanceof File) {
        if (promotionStore.popupMediaUa.value.name.endsWith('.mp4')) {
          tasks.push({
            title: `Загрузка видео ${promotionStore.popupMediaUa.value.name}`,
            action: async () => {
              try {
                const formData = new FormData()
                formData.append(
                  'file',
                  promotionStore.popupMediaUa.value as File,
                )

                const response = await axios.post<{ url: string }>(
                  'v1/uploads/promotion-video',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  },
                )
                promotionStore.popupMediaUa.set(response.data.url)
                return true
              } catch (error) {
                console.error(error)
                showError('При загрузке видео произошла ошибка')
                return false
              }
            },
          })
        } else {
          tasks.push({
            title: `Загрузка изображения ${promotionStore.popupMediaUa.value.name}`,
            action: async () => {
              const url = await uploadImage(
                promotionStore.popupMediaUa.value as File,
                'promotion/popup',
              )
              if (url) {
                promotionStore.popupMediaUa.set(url)
                return true
              }
              return false
            },
          })
        }
      }

      if (promotionStore.popupMediaEn.value instanceof File) {
        if (promotionStore.popupMediaEn.value.name.endsWith('.mp4')) {
          tasks.push({
            title: `Загрузка видео ${promotionStore.popupMediaEn.value.name}`,
            action: async () => {
              try {
                const formData = new FormData()
                formData.append(
                  'file',
                  promotionStore.popupMediaEn.value as File,
                )

                const response = await axios.post<{ url: string }>(
                  'v1/uploads/promotion-video',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  },
                )
                promotionStore.popupMediaEn.set(response.data.url)
                return true
              } catch (error) {
                console.error(error)
                showError('При загрузке видео произошла ошибка')
                return false
              }
            },
          })
        } else {
          tasks.push({
            title: `Загрузка изображения ${promotionStore.popupMediaEn.value.name}`,
            action: async () => {
              const url = await uploadImage(
                promotionStore.popupMediaEn.value as File,
                'promotion/popup',
              )
              if (url) {
                promotionStore.popupMediaEn.set(url)
                return true
              }
              return false
            },
          })
        }
      }
    }
    if (this.activeTabs.includes('promoBar')) {
      if (promotionStore.barImage.value instanceof File) {
        tasks.push({
          title: `Загрузка изображения ${promotionStore.barImage.value.name}`,
          action: async () => {
            const url = await uploadImage(
              promotionStore.barImage.value as File,
              'promotion/promo-bar',
            )
            if (url) {
              promotionStore.barImage.set(url)
              return true
            }
            return false
          },
        })
      }
    }

    if (promotionStore.promotionState === 'new') {
      tasks.push({
        title: 'Создание акции',
        action: promotionStore.createPromotion,
      })
    } else {
      tasks.push({
        title: 'Сохранение акции',
        action: promotionStore.savePromotion,
      })
    }

    return tasks
  }

  protected render() {
    return (
      <LoaderOverlay ref="overlay">
        <div class="mb-1">
          <BButton
            onClick={() => this.savePromotion(false)}
            variant="primary"
            class="mr-1"
          >
            {promotionStore.promotionState === 'new'
              ? 'Создать акцию'
              : 'Сохранить акцию'}
          </BButton>

          <BButton
            onClick={() => this.savePromotion()}
            variant="outline-primary"
          >
            {promotionStore.promotionState === 'new'
              ? 'Создать и закрыть'
              : 'Сохранить и закрыть'}
          </BButton>
        </div>
        <BTabs>
          <BTab
            title="Условия"
            title-link-class={
              promotionStore.conditionsValidator.hasErrors
                ? 'text-danger'
                : null
            }
          >
            <ConditionsTab />
          </BTab>
          <BTab
            title="Лендинг"
            disabled={!promotionStore.activeTabs.includes('landing')}
            title-link-class={
              promotionStore.landingValidator.hasErrors ? 'text-danger' : null
            }
          >
            <LandingTab />
          </BTab>
          <BTab
            title="Блок на страницах курсов"
            disabled={!promotionStore.activeTabs.includes('courseBlock')}
            title-link-class={
              promotionStore.courseBlockValidator.hasErrors
                ? 'text-danger'
                : null
            }
          >
            <BlockTab />
          </BTab>
          <BTab
            title="Промо-полоса"
            disabled={!promotionStore.activeTabs.includes('promoBar')}
            title-link-class={
              promotionStore.promoBarValidator.hasErrors ? 'text-danger' : null
            }
          >
            <BarTab />
          </BTab>
          <BTab
            title="Popup-баннер"
            disabled={!promotionStore.activeTabs.includes('popup')}
            title-link-class={
              promotionStore.popupValidator.hasErrors ? 'text-danger' : null
            }
          >
            <PopupTab />
          </BTab>
        </BTabs>
      </LoaderOverlay>
    )
  }
}
