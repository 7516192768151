import { ICourse } from '@/api/courses'
import { BButton, BCard, BCol, BFormGroup, BRow } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import ImageUploader from '@/components/controls/ImageUploader'
import Select from '@/components/controls/Select'
import CourseSelectTemplate from '@/components/controls/Select/templates/Course'
import { copyTextToClipboard } from '@/helpers/copy'
import { showMessage } from '@/helpers/notifications'
import { promotionStore } from '@/store'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class LandingTab extends tsx.Component<{}> {
  copyUrl() {
    if (
      copyTextToClipboard(
        `${process.env.VUE_APP_MSC_URL}/promo/${promotionStore.landingUrl.value}`,
      )
    ) {
      showMessage('Ссылка скопирована')
    }
  }

  protected render() {
    return (
      <BCard>
        <BRow>
          <BCol sm={6}>
            <FormInput
              label="Url лендинга"
              prepend={`${process.env.VUE_APP_MSC_URL}/promo/`}
              fieldValidator={promotionStore.landingUrl}
              scopedSlots={{
                append: () => (
                  <BButton
                    onClick={this.copyUrl}
                    variant="outline-primary"
                    class="px-50"
                  >
                    <feather-icon icon="CopyIcon" />
                  </BButton>
                ),
              }}
            />
            <FormInput
              label="Заголовок страницы"
              fieldValidator={promotionStore.landingTitle}
            />
            <FormInput
              label="Описание"
              fieldValidator={promotionStore.landingDescription}
              textarea
              rows={3}
            />
            <FormInput
              label="Background страницы (css)"
              fieldValidator={promotionStore.landingBackground}
              textarea
              rows={3}
            />
          </BCol>
          <BCol sm={6}>
            <FormInput
              label="Текст кнопки"
              fieldValidator={promotionStore.landingButtonText}
            />
            <BFormGroup
              label="Курсы для вывода на странице"
              invalid-feedback={promotionStore.landingCourses.errorText}
              state={promotionStore.landingCourses.hasError ? false : null}
            >
              <Select
                value={promotionStore.landingCourses.value}
                onInput={promotionStore.landingCourses.set}
                options={promotionStore.courses}
                closeOnSelect={false}
                placeholder="Выберите курсы"
                label="title"
                state={promotionStore.landingCourses.hasError ? false : null}
                scopedSlots={{
                  selectedOption: (option: ICourse) => (
                    <CourseSelectTemplate
                      title={option.title}
                      image={option.image.url}
                      hex={option.image.color}
                      selected
                    />
                  ),
                  option: ({ option }: { option: ICourse }) => (
                    <CourseSelectTemplate
                      title={option.title}
                      image={option.image.url}
                      hex={option.image.color}
                    />
                  ),
                }}
              />
            </BFormGroup>

            <BFormGroup
              label="Изображение"
              invalid-feedback={promotionStore.landingImage.errorText}
              state={promotionStore.landingImage.hasError ? false : null}
            >
              <ImageUploader
                value={promotionStore.landingImage.value}
                onInput={promotionStore.landingImage.set}
                state={promotionStore.landingImage.hasError ? false : null}
                width="300px"
              />
            </BFormGroup>
          </BCol>
        </BRow>
      </BCard>
    )
  }
}
