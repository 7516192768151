import { BCard, BCol, BFormGroup, BRow } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import ImageUploader from '@/components/controls/ImageUploader'
import { imageFormFile } from '@/helpers/image'
import { promotionStore } from '@/store'
import { Component, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

import './style.scoped.scss'

@Component
export default class BlockTab extends tsx.Component<{}> {
  imagePreview = ''

  get image() {
    return promotionStore.blockImage.value
  }

  @Watch('image')
  async onImageChanged() {
    if (this.image instanceof File) {
      this.imagePreview = await imageFormFile(this.image)
    } else if (typeof this.image === 'string') {
      this.imagePreview = this.image
    } else {
      this.imagePreview = ''
    }
  }

  async mounted() {
    if (typeof promotionStore.blockImage.value === 'string') {
      this.imagePreview = promotionStore.blockImage.value
    }
  }

  protected render() {
    return (
      <BCard>
        <BRow>
          <BCol sm={6}>
            <FormInput
              label="Цвет текста"
              fieldValidator={promotionStore.blockColor}
            />
            <FormInput
              label="Background блока (css)"
              fieldValidator={promotionStore.blockBackground}
              textarea
              rows={3}
            />
          </BCol>
          <BCol sm={6}>
            <BFormGroup
              label="Изображение"
              invalid-feedback={promotionStore.blockImage.errorText}
              state={promotionStore.blockImage.hasError ? false : null}
            >
              <ImageUploader
                value={promotionStore.blockImage.value}
                onInput={promotionStore.blockImage.set}
                state={promotionStore.blockImage.hasError ? false : null}
                width="150px"
              />
            </BFormGroup>
          </BCol>
        </BRow>
        <BFormGroup label="Предпросмотр">
          <div
            class="block-preview d-flex align-items-center"
            style={{
              color: promotionStore.blockColor.value,
              background: promotionStore.blockBackground.value,
            }}
          >
            <div class="image">
              {!!promotionStore.blockImage.value && (
                <img src={this.imagePreview} alt="" />
              )}
            </div>
            <div class="info d-flex flex-column">
              <span class="date">Только с 4 по 18 марта</span>
              <span class="discount">
                Скидка -{promotionStore.discount.value}%
              </span>
            </div>
            <div
              class="separator"
              style={{ borderColor: promotionStore.blockColor.value }}
            ></div>
            <div class="d-flex flex-column flex-grow-1 mr-50">
              <span>Осталось</span>
              <span class="timer">3 : 23 : 59 : 02</span>
            </div>
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.25 6.75L6.75 20.25"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 6.75H20.25V18"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </BFormGroup>
      </BCard>
    )
  }
}
