import {
  BButton,
  BCard,
  BCol,
  BFormFile,
  BFormGroup,
  BRow,
} from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import Switcher from '@/components/controls/Switcher'
import { copyTextToClipboard } from '@/helpers/copy'
import { imageFormFile } from '@/helpers/image'
import { showMessage } from '@/helpers/notifications'
import { i18nStore, promotionStore } from '@/store'
import { Component, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

import './style.scoped.scss'

@Component
export default class PopupTab extends tsx.Component<{}> {
  isPreviewVisible = false
  imagePreview = ''

  get image() {
    return this.popupMedia.value
  }

  get isLandingActive() {
    return promotionStore.activeTabs.includes('landing')
  }

  get landingUrl() {
    return promotionStore.landingUrl.value
  }

  get uselandingUrl() {
    return promotionStore.popupUseLandingUrl && this.isLandingActive
  }

  get popupMedia() {
    switch (i18nStore.selectedLanguage) {
      case 'ru':
        return promotionStore.popupMediaRu
      case 'uk':
        return promotionStore.popupMediaUa
      case 'en':
        return promotionStore.popupMediaEn
    }
  }

  get imageUrl() {
    if (
      typeof this.popupMedia.value === 'string' &&
      !this.popupMedia.value.endsWith('.mp4')
    ) {
      return this.popupMedia.value
    }
    return null
  }

  get videoUrl() {
    if (
      typeof this.popupMedia.value === 'string' &&
      this.popupMedia.value.endsWith('.mp4')
    ) {
      return this.popupMedia.value
    }
    return null
  }

  get isNewVideo() {
    return (
      this.popupMedia.value instanceof File &&
      this.popupMedia.value.name.endsWith('.mp4')
    )
  }

  @Watch('landingUrl')
  onLandingUrlChanged(value: string) {
    if (this.uselandingUrl) {
      promotionStore.popupUrl.set(`promo/${value}`)
    }
  }

  @Watch('uselandingUrl')
  onUseUrlChanged() {
    if (this.uselandingUrl) {
      promotionStore.popupUrl.set(`promo/${promotionStore.landingUrl.value}`)
    } else {
      promotionStore.popupUrl.set('')
    }
    promotionStore.popupUrl.reset()
  }

  @Watch('image')
  async onImageChanged() {
    if (this.image instanceof File) {
      this.imagePreview = await imageFormFile(this.image)
    } else if (typeof this.image === 'string') {
      this.imagePreview = this.image
    } else {
      this.imagePreview = ''
    }
  }

  copyUrl() {
    if (
      copyTextToClipboard(
        `${process.env.VUE_APP_MSC_URL}/${promotionStore.popupUrl.value}`,
      )
    ) {
      showMessage('Ссылка скопирована')
    }
  }

  async mounted() {
    if (
      typeof this.popupMedia.value === 'string' &&
      !this.popupMedia.value.endsWith('.mp4')
    ) {
      this.imagePreview = this.popupMedia.value
    }
  }

  protected render() {
    return (
      <BCard>
        <BRow>
          <BCol sm={6}>
            <FormInput
              label="Ссылка"
              fieldValidator={promotionStore.popupUrl}
              disabled={this.uselandingUrl}
              prepend={`${process.env.VUE_APP_MSC_URL}/`}
              scopedSlots={{
                top: () =>
                  this.isLandingActive ? (
                    <Switcher
                      value={promotionStore.popupUseLandingUrl}
                      onInput={value =>
                        (promotionStore.popupUseLandingUrl = value)
                      }
                    >
                      Использовать url лендинга
                    </Switcher>
                  ) : null,
                append: () => (
                  <BButton
                    onClick={this.copyUrl}
                    variant="outline-primary"
                    class="px-50"
                  >
                    <feather-icon icon="CopyIcon" />
                  </BButton>
                ),
              }}
            />
            <FormInput
              label="Текст кнопки"
              fieldValidator={promotionStore.popupButtonText}
            />
            <FormInput
              label="Цикл показа (часы)"
              fieldValidator={promotionStore.popupTimer}
              type="number"
            />
          </BCol>
          <BCol sm={6}>
            <BFormGroup
              label="Изображение или видео (RU)"
              invalid-feedback={promotionStore.popupMediaRu.errorText}
              state={promotionStore.popupMediaRu.hasError ? false : null}
            >
              <BFormFile
                onInput={promotionStore.popupMediaRu.set}
                placeholder="Выберите файл..."
                drop-placeholder="Переместите файл сюда..."
                browse-text="Обзор"
                accept=".jpg, .jpeg, .png, .svg, .mp4"
                state={promotionStore.popupMediaRu.hasError ? false : null}
              />

              {typeof promotionStore.popupMediaRu.value === 'string' && (
                <a
                  href={promotionStore.popupMediaRu.value}
                  target="_blank"
                  class="mt-50 d-block"
                >
                  Ссылка на загруженное медиа
                </a>
              )}
            </BFormGroup>
            <BFormGroup
              label="Изображение или видео (UA)"
              invalid-feedback={promotionStore.popupMediaUa.errorText}
              state={promotionStore.popupMediaUa.hasError ? false : null}
            >
              <BFormFile
                onInput={promotionStore.popupMediaUa.set}
                placeholder="Выберите файл..."
                drop-placeholder="Переместите файл сюда..."
                browse-text="Обзор"
                accept=".jpg, .jpeg, .png, .svg, .mp4"
                state={promotionStore.popupMediaUa.hasError ? false : null}
              />

              {typeof promotionStore.popupMediaUa.value === 'string' && (
                <a
                  href={promotionStore.popupMediaUa.value}
                  target="_blank"
                  class="mt-50 d-block"
                >
                  Ссылка на загруженное медиа
                </a>
              )}
            </BFormGroup>
            <BFormGroup
              label="Изображение или видео (EN)"
              invalid-feedback={promotionStore.popupMediaEn.errorText}
              state={promotionStore.popupMediaEn.hasError ? false : null}
            >
              <BFormFile
                onInput={promotionStore.popupMediaEn.set}
                placeholder="Выберите файл..."
                drop-placeholder="Переместите файл сюда..."
                browse-text="Обзор"
                accept=".jpg, .jpeg, .png, .svg, .mp4"
                state={promotionStore.popupMediaEn.hasError ? false : null}
              />

              {typeof promotionStore.popupMediaEn.value === 'string' && (
                <a
                  href={promotionStore.popupMediaEn.value}
                  target="_blank"
                  class="mt-50 d-block"
                >
                  Ссылка на загруженное медиа
                </a>
              )}
            </BFormGroup>
            <BButton
              variant="outline-primary"
              onClick={() => (this.isPreviewVisible = !this.isPreviewVisible)}
            >
              Предпросмотр
            </BButton>
          </BCol>
        </BRow>

        {this.isPreviewVisible && (
          <div
            class="popup-preview"
            style={{
              backgroundImage: `url(${this.imagePreview || this.imageUrl})`,
            }}
            onClick={() => (this.isPreviewVisible = false)}
          >
            {!!this.videoUrl && (
              <video loop muted autoplay>
                <source
                  src={this.videoUrl}
                  type="video/mp4"
                  //@ts-ignore
                  preload="none"
                />
              </video>
            )}
            {this.isNewVideo && (
              <div class="new-video-message">
                <span>
                  Видео будет доступно после загрузки и обработки на сервере
                </span>
              </div>
            )}
            <div class="button">
              {promotionStore.popupButtonText.value[i18nStore.selectedLanguage]}
            </div>

            <div class="close-btn">
              <feather-icon icon="XIcon" size="25" />
            </div>
          </div>
        )}
      </BCard>
    )
  }
}
