import { ICourse } from '@/api/courses'
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BInput,
  BRow,
} from '@/components/bootstrap'
import DateTimePicker from '@/components/controls/DateTimePicker'
import FormInput from '@/components/controls/form/FormInput'
import Select from '@/components/controls/Select'
import CourseSelectTemplate from '@/components/controls/Select/templates/Course'
import { promotionStore } from '@/store'
import { PromotionTab } from '@/store/promotionStore'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

import './style.scoped.scss'

@Component
export default class ConditionsTab extends tsx.Component<{}> {
  tabs: { text: string; value: PromotionTab }[] = [
    { text: 'Лендинг', value: 'landing' },
    { text: 'Блок на страницах курсов', value: 'courseBlock' },
    { text: 'Промо-полоса', value: 'promoBar' },
    { text: 'Popup-баннер', value: 'popup' },
  ]

  selectedCourse: ICourse | null = null

  get filteredCourses() {
    return promotionStore.courses.filter(
      course => !promotionStore.customDiscounts.some(d => d.course === course),
    )
  }

  onSelectCourse(course: ICourse | null) {
    if (course) {
      promotionStore.addCustomDiscount({ course, value: 0 })
    }
    this.selectedCourse = course
    this.$nextTick(() => (this.selectedCourse = null))
  }

  protected render() {
    return (
      <BCard class="conditions-tab">
        <BRow>
          <BCol sm={6}>
            <FormInput
              label="Название акции"
              fieldValidator={promotionStore.title}
            />
            <FormInput
              label="Описание"
              fieldValidator={promotionStore.description}
              textarea
              rows={3}
            />

            <BFormGroup
              label="Начало действия (Киев)"
              state={promotionStore.dateStart.hasError ? false : null}
              invalid-feedback={promotionStore.dateStart.errorText}
            >
              <DateTimePicker
                value={promotionStore.dateStart.value}
                onInput={promotionStore.dateStart.set}
                enableTime
                dateFormat="Y-m-d H:i:S"
                displayedFormat="l d M Y H:i"
              />
            </BFormGroup>

            <BFormGroup
              label="Окончание действия (Киев)"
              state={promotionStore.dateEnd.hasError ? false : null}
              invalid-feedback={promotionStore.dateEnd.errorText}
            >
              <DateTimePicker
                value={promotionStore.dateEnd.value}
                onInput={promotionStore.dateEnd.set}
                enableTime
                dateFormat="Y-m-d H:i:S"
                displayedFormat="l d M Y H:i"
              />
            </BFormGroup>
          </BCol>
          <BCol sm={6}>
            <b-form-checkbox-group
              checked={promotionStore.activeTabs}
              onInput={(value: PromotionTab[]) =>
                (promotionStore.activeTabs = value)
              }
              options={this.tabs}
              switches
              class="mb-50"
            />

            <FormInput
              label="Скидка (%)"
              fieldValidator={promotionStore.discount}
              type="number"
            />

            <BFormGroup label="Добавить отдельную скидку на курс">
              <Select
                value={this.selectedCourse}
                onInput={this.onSelectCourse}
                options={this.filteredCourses}
                placeholder="Выберите курс"
                label="title"
                class="mb-50"
                scopedSlots={{
                  selectedOption: (option: ICourse) => (
                    <CourseSelectTemplate
                      title={option.title}
                      image={option.image.url}
                      hex={option.image.color}
                      selected
                    />
                  ),
                  option: ({ option }: { option: ICourse }) => (
                    <CourseSelectTemplate
                      title={option.title}
                      image={option.image.url}
                      hex={option.image.color}
                    />
                  ),
                }}
              />
              {promotionStore.customDiscounts.map(d => (
                <div
                  class={[
                    'border rounded shadow-sm mb-50 p-25',
                    { 'border-danger': d.discount.hasError },
                  ]}
                >
                  <div class="d-flex">
                    <CourseSelectTemplate
                      title={d.course.title}
                      image={d.course.image.url}
                      hex={d.course.image.color}
                      selected
                      class="flex-grow-1 mr-50"
                    />
                    <BInput
                      value={d.discount.value}
                      onInput={d.discount.set}
                      state={d.discount.hasError ? false : null}
                      type="number"
                      style={{ width: '80px' }}
                      class="mr-25"
                    />
                    <BButton
                      onClick={() => promotionStore.removeCustomDiscount(d)}
                      variant="outline-danger"
                      class="px-50"
                    >
                      <feather-icon icon="TrashIcon" />
                    </BButton>
                  </div>
                  {d.discount.hasError && (
                    <small class="text-danger">{d.discount.errorText}</small>
                  )}
                </div>
              ))}
            </BFormGroup>
          </BCol>
        </BRow>
      </BCard>
    )
  }
}
